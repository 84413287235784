import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { heavyFontWeight } from '@rsa-digital/evo-shared-components/theme/JohnLewis/fonts';
import styled from 'styled-components';
import PanelWithActionLink from 'components/PanelWithActionLink';
import RichTextLarge from 'components/RichTextLarge';

export const PrivacyPolicy = styled(RichTextLarge)`
  margin-top: ${spacing(2)};

  ${RichText} {
    p {
      ${mediaQuery.tabletPortrait`
        ${fonts.paragraphLarge}
      `}
    }
  }
`;

export const PrivacyPolicyHeading = styled.h2`
  ${fonts.headingSmall}

  margin: ${spacing(6)} 0 ${spacing(2)};
`;

export const HeadingMedium = styled.h2`
  margin: ${spacing(1)} 0 ${spacing(2)};
  ${fonts.paragraphLarge}
  && {
    font-weight: ${heavyFontWeight};
    font-size: 20px;
    line-height: 28.68px;
    letter-spacing: 0.005em;
  }
`;

export const PanelWithActionLinkWithMargins = styled(PanelWithActionLink)`
  margin: ${spacing(2)} 0 ${spacing(4)};

  && {
    box-shadow: none;
    border: 1px solid ${colors.core01};
    background-color: ${colors.core02};
    ${mediaQuery.tabletPortrait`
      padding: ${spacing(4)};
    `}
  }
`;

export const ParagraphLargeWithMargins = styled.p`
  ${fonts.paragraphLarge};
  margin: ${spacing(4)} 0 ${spacing(2)};
`;
